.light {
    //Colors
    $color-primary : #0E0E10;
    $color-secondary : #5F5F6B;
    $color-tally: #0E0E10;
    $color-success:#3EA248;
    $color-active:#42A8E1;
    $color-failed:#D94C48;
    //Backgrounds
    $background-card:#F9FAFA;
    $background-success:#E9F7EA;
    $background-active:#E5F3FB;
    $background-failed:#FAE6E6;
    $shadow-card:4px 4px 32px rgba(140, 140, 140, 0.25);

    &.proposal-row{
        background: $background-card;
        box-shadow: $shadow-card;
        p{
            color: $color-secondary;
            &.title{
                color: $color-primary;
            }
        }
        span{
            color: $color-secondary;
        }
    }
    .status{
        &-active{
           background: $background-active;
            color: $color-active;
        }
        &-tally{
            &.in{
               &.progress{
                    background:  $background-card;
                    color: $color-tally;
                    border: 1px solid $color-tally;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
        &-in{
            &.progress{
                background: $background-active;
                color: $color-active;
            }
        }
        &-failed{
            background: $background-failed;
            color: $color-failed;
        }
        &-passed{
            background: $background-success;
            color: $color-success;
        }
    }
}
.dark {
    //Colors
    $color-primary : white;
    $color-secondary : #c9c9c9;
    $color-success:#5fb869;
    $color-active:#42A8E1;
    $color-failed:#dc5955;
    //Backgrounds
    $background-card:#000;
    $background-success:#E9F7EA;
    $background-active:#E5F3FB;
    $background-failed:#FAE6E6;
    $shadow-card: 0px 0px 10px 1px rgba(140, 140, 140, 0.88);

    &.proposal-row{
        background: $background-card;
        box-shadow: $shadow-card;
        p{
            color: $color-secondary;
            &.title{
                color: $color-primary;
            }
        }
        span{
            color: $color-secondary;
        }
    }
    .status{
        &-passed{
            background: $background-success;
            color: $color-success;
        }
        &-tally{
            &.in{
               &.progress{
                    background:  $background-card;
                    color: $color-primary;
                    border: 1px solid $color-primary;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
        &-active{
            background: $background-active;
            color: $color-active;

        }
        &-failed{
            background: $background-failed;
            color: $color-failed;
        }
    }
}
.proposal-row{
    border-radius: 16px;
    text-decoration: none;
    // cursor: pointer;
    display: grid;
    align-items: center;
    justify-content: space-around;
    grid-auto-flow: column;
    padding: 26px;
    width: 100%;
    grid-template-columns: 5% 40% 22% 20%;
    p{
        font-weight: 600;
        font-size: 22px;
        margin: 0;
    }
    span{
        font-size: 16px;
    }
    div{
        display: grid;
        row-gap: 4px;
    }
}
.vote-end{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.proposal-status{
    max-width: 200px;
    font-size: 22px;
    padding: 14px;
    border-radius: 4px;
    font-weight: 600;
    display: grid;
    align-items: center;
    justify-content: center;
}