:root {
  --font-p-color-neutral-01: #0E0E10;
  --font-p-color-neutral-03: #5F5F6B;
  --font-family: Poppins;
  --font-p-color-green-01: #cb9b51;
  --font-p-color-pink-01: #cb9b51;
}



body{
    font-family: var(--font-family);
}

h3{
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0.01em;
    text-align: left;
}

.proposal-container{
    display: grid;
    grid-template-columns: repeat(2, 65% 35%);
    grid-gap: 16px;
    margin-top: 32px;
}

@media only screen and (max-width: 1150px) {
    .proposal-container {
        grid-template-columns: auto;
        grid-template-rows: auto;
        margin-top: 0;
    }

    .cards {
        grid-row: 1;
        display: flex;
        flex-direction: column-reverse;
    }

    .card-info {
        margin-top: 1rem;
    }
}

.proposal-detail{
    &.light{



        box-shadow: 4px 4px 32px rgba(140, 140, 140, 0.25);
        $font-gray:#5F5F6B;
        $card-background:white;
        background: $card-background;

        .vote-row{
            p{
                color: $font-gray;
            }
        }
        .proposal-content{
            .title,.description{

                p{
                    color: $font-gray;
                }

            }
        }
    }
    &.dark{
        // box-shadow: 4px 4px 32px rgba(140, 140, 140, 0.25);
        $font-gray:#ccc;
        $card-background:#000;
        background: $card-background;
        .vote-row{
            p{
                color: $font-gray;
            }
        }
        .proposal-content{
            .title,.description{
                p{
                    color: $font-gray;
                }
                h5{
                    color: white;
                }
            }
        }
    }
}

.proposal-detail{
    padding: 26px;
    border-radius: 16px;
    .vote-row{
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: space-between;
        p,h3{
            margin: 0;
            font-weight: normal;
        }
        p{
            font-weight: 600;
        }
    }
    .proposal-status {
        &.small {
            font-size: 16px;
            padding: 4;
            max-width: 161px;
        }
    }
    .proposal-id{
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: auto auto;
        column-gap: 10px;
        justify-content: start;
        align-items: center;
    }
    .proposal-content{
        .title{
            display: grid;
            grid-auto-flow: row;
            row-gap: 8px;
            padding-top: 8px;
            h1{
                margin: 0;
            }
        }
        .description{
            display: grid;
            grid-auto-flow: row;
            row-gap: 16px;
            padding-top: 26px;

            .wrap-space{
                white-space: pre-line;
            }

            h5{
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                color: var(--font-p-color-neutral-03);
                margin: 0;
            }

            p{
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: var(--font-p-color-neutral-01);
            }
        }
    }

}

h5{
    font-family: var(--font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.cards{

    &.dark{
        .card{
            box-shadow: none;
            background: #0E0E10;

            p{
                color: #FFFFFF;
            }
        }
    }

    .label{
        p{
            font-family: var(--font-family);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--font-p-color-neutral-03);
        }
    }

    .title{
        p{
            font-family: var(--font-family);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--font-p-color-neutral-01);
        }
    }


    .card{
        background-color: white;
        padding: 26px;
        border-radius: 16px;
        box-shadow: 4px 4px 32px rgba(140, 140, 140, 0.25);
    }
}


.card-info{

    //Colors
    $color-primary : #0E0E10;
    $color-secondary : #5F5F6B;
    $color-tally: #0E0E10;
    $color-success:#3EA248;
    $color-active:#42A8E1;
    $color-failed:#D94C48;
    //Backgrounds
    $background-card:#F9FAFA;
    $background-success:#E9F7EA;
    $background-active:#E5F3FB;
    $background-failed:#FAE6E6;
    $shadow-card:4px 4px 32px rgba(140, 140, 140, 0.25);

    h5{
        margin-bottom: 30px;
    }

    .card-row{
        display: grid;
        grid-template-columns: 50% 50%;
        margin-bottom: 16px;
    }

    .proposal-status {
        &.small {
            font-size: 16px;
            padding: 4;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;

            &.status{
                &-active{
                    background: $background-active;
                    color: $color-active;
                }
                &-tally{
                    &.in{
                    &.progress{
                            background:  $background-card;
                            color: $color-tally;
                            border: 1px solid $color-tally;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
                &-in{
                    &.progress{
                        background: $background-active;
                        color: $color-active;
                    }
                }
                &-failed{
                    background: $background-failed;
                    color: $color-failed;
                }
                &-passed{
                    background: $background-success;
                    color: $color-success;
                }
            }
        }


    }

    .user-info{

        .address{
            color: var(--font-p-color-pink-01);

            a{
                text-decoration: none;
                color: var(--font-p-color-pink-01);
            }
        }
    }

    .user-response{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;

        .voting-power{
            h3{
                color: var(--font-p-color-neutral-03);
            }
        }

        .vote-response{
            h3 {
                color: var(--font-p-color-green-01);
            }
        }
    }

    .button-vote{
        margin-top: 10px;
        display: block;
        width: 100%;
        padding: 11px 42px;
        font-family: var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        cursor: pointer;
    }
}

.card-results{
    margin-top: 16px;
    display: grid;
    grid-template-rows: repeat(2);

    h5{
        margin-bottom: 10px;
    }


    p.description{
        font-family: var(--font-family);;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(147, 147, 159, 1);
    }

    .endTime{
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .closed-proposal{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;
        margin-top: 16px;

        h3{
            text-align: center;
        }

        p{
            text-align: center;
        }

        .voted{
            color: var(--font-p-color-neutral-03);
        }

        .result{
            h3{ color: var(--font-p-color-green-01)}
        }
    }

    .button-finalize-vote{
        margin-top: 26px;
        display: block;
        width: 100%;
        padding: 11px 42px;
        font-family: var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        cursor: pointer;
    }
}