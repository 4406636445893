.light{
    $background-button:black;
    $color-button:white;
    $color-pink:#cb9b51;
    $color-pink-transparent:#e9ddc8;
    $color-gray: #5F5F6B;
    .g-button{
        background: $background-button;
        color: $color-button;
        &--outline{
            background: transparent;
            border: $background-button 1px solid;
            color: $background-button;
        }
    }
    .border{
        border: 1px solid $background-button;
    }
    .hero-governance{
        h1{
            color: $color-pink;
        }
        span,p{
            color: $color-gray;
            &.pink{
                color: $color-pink;
            }
        }
    }
    .content-governance{
        &__title{
            color: $color-gray;
        }
        &__note{
            color: $color-gray;
        }
    }
    .filters{
        .active{
            background: $color-pink-transparent;
            color: $color-pink;
            &:hover{
                background: $color-pink-transparent;
                color: $color-pink;
            }
        }
    }
}
.dark{
    $background-button:white;
    $color-button:black;
    $color-pink:#cb9b51;
    $color-pink-transparent:#e9ddc8;
    $color-gray: #fff;
    .g-button{
        background: $background-button;
        color: $color-button;
        &--outline{
            background: transparent;
            border: $background-button 1px solid;
            color: $background-button;
        }
    }
    .border{
        border: 1px solid $background-button;
    }
    .hero-governance{
        h1{
            color: $color-pink;
        }
        span,p{
            color: $color-gray;
            &.pink{
                color: $color-pink;
            }
        }
    }
    .content-governance{
        &__title{
            color: $color-gray;
        }
        &__note{
            color: $color-gray;
        }
    }
    .filters{
        .active{
            background: $color-pink-transparent;
            color: $color-pink;
            &:hover{
                background: $color-pink-transparent;
                color: $color-pink;
            }
        }
    }
}

.column-stats{
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    h1{
        margin: 0
    }
    p{
        position: absolute;
    }
    // grid-auto-flow: column;

    .stats-power {
       p{
           margin-top: 17px;
       }
    }

    .stats-power-loaded{
        p{
           margin-top: 0px;
       }
    }

    .stats-apy{
        h1{
            height: 40px;
        }
    }


}
.hero-governance{
    display: grid;
    width: 100%;
    grid-template-columns:75% 25%;
    padding: 32px 0;
    .buttons{
        display: grid;
        justify-content: flex-end;
        grid-template: auto auto / auto;
        &-container{
            row-gap: 10px;
            display: flex;
            flex-direction: row;
            max-width: 250px;
            flex-wrap: wrap;
            justify-content: flex-end;
            grid-template: auto auto / auto;
        }
    }

    .icon-info__popup {
        background: #D6D6D6;
        border-radius: 4px;
        border: none;
        width: 411px;
        height: 38px;
        font-family: arial;
        font-weight: 600;
        font-style: normal;
        padding: 11px 20px;

        p {
            color: #fff !important;
            font-size: 12px;
        }
    }

    h1{
        font-weight: 600;
        size: 36px;
    }
    span,p{
        font-weight: 600;
        font-size: 16px;

    }
}
.content-governance{
    width: 100%;
    &__title{
        h3{
            font-size: 28px;
            margin: 0;
            letter-spacing: 0.01em;
            font-weight: 600;
        }
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }
    &__note{
        h3{
            font-size: 28px;
            margin: 0;
            letter-spacing: 0.01em;
            font-weight: 600;
        }
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
    }
    .filters{
        justify-content: flex-end;
        display: grid;
        grid-auto-flow: column;
    }

}
.row{
    display: grid;
    grid-auto-flow: row;
}
.g-button{
    margin: 10px 0;
    &--outline{
        width: fit-content;
    }
}
.list-proposal{
    padding: 33px 0;
    display: grid;
    grid-auto-flow: row;
    row-gap: 26px;
}

//force semantic-ui-react to use this font family
h1,h2,h3,h4,h5,h6{
    font-family: 'poppins',Arial, Helvetica, sans-serif;
}
*{
    font-family: 'poppins',Arial, Helvetica, sans-serif;
}

.ui.button > a{
    text-decoration: none;
    color: inherit;
}

.how-it-works.modal .content {
  padding: unset !important;
}
.how-it-works.ui.modal>.actions {
  background: white;
  padding: 16px !important;

  &.dark {
    background: rgb(14, 14, 16);
  }
}
