.withdrawAmountBtn.ui.basic.button {
  flex: 1;
  border-radius: 12px;
  padding: 10px;
  margin: 0.5rem;
  color: #cb9b51 !important;
  border: 1px solid #cb9b51 !important;
}

.withdrawBtn.ui.primary.button {
  border-radius: 12px;
  padding: 10px 20px;
  background-color: #cb9b51 !important;
  margin: 0.5rem 0 0.75rem 0;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 7px;
  background: grey;
  border-radius: 5px;
  background-image: linear-gradient(#cb9b51, #cb9b51);
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #cb9b51;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: #cb9b5150 0px 0px 0px 8px;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: #cb9b5150 0px 0px 0px 11px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}