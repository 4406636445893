$width: 50px;
$height: 25px;
$primary-color: var(--font-p-color-pink-01);
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: $height - 8;
  width: $height - 8;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX($width - 24);
  -ms-transform: translateX($width - 24);
  transform: translateX($width - 24);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.poolToggle{
  margin: 0;
  padding: 0 .5rem;
  &.light{
    color: black;
  }
  &.dark{
    color: whitesmoke;
  }
}
