.convert-wrapper {
  width: 568px;
  padding: 26px;
  border-radius: 16px;
  height: fit-content;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ui.basic.button {
      box-shadow: none;
      -webkit-box-shadow: none;
      &.inverted {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
    }
  }
  .convert-learn-more {
    width: 100%;
    a {
      text-align: start;
      color: #cb9b51;
    }
  }
  .contenas {
    width: 100%;
    display: grid;
    gap: 10px 0;
    .ui.big.input {
      width: 100%;
    }
  }
}

.light {
  &.convert-wrapper {
    color: black;
    background: white;
    box-shadow: 4px 4px 32px rgba(140, 140, 140, 0.25);
    .description {
      color: #5f5f6b;
    }
  }
}
.dark {
  &.convert-wrapper {
    color: white;
    background: #000;
    box-shadow: 0px 0px 10px 1px rgba(140, 140, 140, 0.88);
    .description {
      color: #999;
    }
  }
}

@media only screen and (max-width: 768px) {
  .convert-wrapper {
    width: 100%;
  }
}
