.swap-container-style.ui.container {
  margin: 0 !important;
}

i.circular.icon.additional-info-icon {
  margin-left: 0.5rem;
  margin-bottom: 2px;
  vertical-align: middle;

  &.dark, &.dark:before {
    background: rgba(255, 255, 255, 0.1);
    color: white;
  }
  &.light, &.light:before {
    background: whitesmoke;
    color: black;
  }
}